import { useState, useEffect, useCallback } from "react";
import { render } from "react-dom";
import { ReactGrid, } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import './App.css';
import Twitter from './twitter.png';

const defaultRows = 10;

function generateRow (address) { return  { address, balance: "" } };

const getAddresses = (num) => new Array(num ?? defaultRows).fill(null).map(_ => generateRow(""));

const getColumns = ()=> [
  { columnId: "address", width: 500, },
  { columnId: "balance", }
];

const headerRow= {
  rowId: "header",
  cells: [
    { type: "header", text: "Address" },
    { type: "header", text: "Balance" }
  ]
};

const getRows = (addresses) => [
  headerRow,
  ...addresses.map((person, idx) => ({
    rowId: idx,
    cells: [
      { type: "text", text: person.address },
      { type: "number", value: person.balance }
    ]
  }))
];

const pending = {};

function updateAddressBalance(addr, setAddresses, balance) {
  setAddresses(addresses => addresses.map((entry) => {
    if (entry.address == addr) {
      entry.balance = balance;
    }
    return entry;
  }));
}

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

async function lookup(address) {
  try {
    const r = await fetch(`https://mainnet-api.algonode.network/v2/accounts/${address}`);
    const j = await r.json();
    await sleep(100);
    return j?.amount ? (j.amount / 1_000_000) : 0;
  } catch(e) {
    return e.message;
  }
}

async function lookupBalances(addresses, setAddresses) {
  for(const address of addresses) {
    if (pending[address]) {
      continue;
    }
    pending[address] = true;
    let balance;
    try {
      balance = await lookup(address);
    } catch(e) {
      balance = e.message;
      console.error(e);
    }
    updateAddressBalance(address, setAddresses, balance);
    delete pending[address]
  }
}

function App() {
  const [addresses, setAddresses] = useState(getAddresses());
  const [needUpdate, setNeedUpdate] = useState([]);
  const [sum, setSum] = useState(0);

  const rows = getRows(addresses);
  const columns = getColumns();

  useEffect(() => {
    setSum(addresses.reduce((sum, {address, balance}) => {
      return balance ? sum + balance : sum;
    }, 0));
  }, [addresses]);

  useEffect(() => {
    console.error("Looking up");
    lookupBalances(needUpdate, setAddresses);
  }, [needUpdate]);

  useEffect(() => {
    const needUpdates = addresses.map(({address, balance}) =>
      address?.length === 58 && !balance ? address : null
    ).filter(Boolean);
    if (needUpdates.length) {
      const newNeedUpdates = needUpdates.filter((address) => !needUpdate.includes(address));
      if (newNeedUpdates.length) {
        console.log('new need updates', newNeedUpdates);
        setNeedUpdate(newNeedUpdates);
      }
    }
  }, [addresses, needUpdate]);

  const applyChangesToAddresses = useCallback((
    changes,
    prevAddresses,
  ) => {
    changes.forEach((change) => {
      const idx = change.rowId;
      const field= change.columnId;
      prevAddresses[idx][field] = change.newCell.text;
      if (field === "address") {
        prevAddresses[idx].balance = "";
      }
    });
    return [...prevAddresses];
  });

  const handleChanges = useCallback((changes) => {
    setAddresses((prevAddresses) => applyChangesToAddresses(changes, prevAddresses)); 
  }, []);

  const add50Rows = useCallback(() => {
    setAddresses(addresses => [...addresses, ...getAddresses(50)]);
  }, []);

  const copy = useCallback(() => (async () => {
    let content = 'Address\tBalance\n';
    addresses.forEach(({address, balance}) => content += `${address}\t"${balance}"\n`);
    await navigator.clipboard.writeText(content);
  })(), [addresses]);

  const paste = useCallback(() => {
    (async () => {
      try {
        const t = await navigator.clipboard.readText();
        const newAddresses = [];
        for(const address of t.split("\n")) {
          if (address.trim().length)
            newAddresses.push(generateRow(address));
        }
        setAddresses(addr => [...addr.filter(({address, balance}) => address || balance), ...newAddresses]);
      } catch(e) {
        if (e.message.includes('is not a function')) {
          alert('Your browser does not support the Paste button.\nYou can still paste manually.\nMake sure there are enough rows to paste into by clicking the Add Rows button');
        } else {
          alert('Something went wrong: '+e.message);
        }
      }
    })()
  }, []);

  const setPreset = useCallback((needle) => {
    (async ()=>{
      try {
        const resp = await fetch(`https://flow.algo.surf/address-book.json`);
        const addressBook = await resp.json();
        const f = Object.entries(addressBook)
          .filter( ([addr, name]) => name.includes(needle) )
          .map(([addr]) => generateRow(addr));
        setAddresses(f);
      } catch(e) {
        alert(e.message);
      }
    })()
  }, []);

  const setPresetFoundation = useCallback(() => setPreset('Foundation'), []);
  const setPresetAlgorandinc = useCallback(() => setPreset('Algorand Inc'), []);
  const setPresetBinance = useCallback(() => setPreset('Binance'), []);
  const setPresetCoinbase = useCallback(() => setPreset('Coinbase'), []);

  const copySum = useCallback(() => {
    navigator.clipboard.writeText(sum);
  }, [sum]);

  const gotoTwitter = useCallback(() => window.open('https://twitter.com/algo_surf'), []);

  const exportCSV = useCallback(() => {
    let csvContent = "data:text/csv;charset=utf-8,Address,Balance\n";
    addresses.forEach(({address, balance}) => csvContent += `"${address.replace(/"/g,'\\"')}","${balance}"\n`);
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }, [addresses]);

  return <div className="page-container">
    <img src={Twitter} className="twitter" onClick={gotoTwitter}/>
    <div className="container">
      <h1>batch.algo.surf</h1>
      <h2>Algorand Batch Balance Lookup</h2>
      <div className="padded">Presets: 
        <button className="padded" onClick={setPresetAlgorandinc}>Algorand Inc</button>
        <button className="padded" onClick={setPresetFoundation}>Foundation</button>
        <button className="padded" onClick={setPresetBinance}>Binance</button>
        <button className="padded" onClick={setPresetCoinbase}>Coinbase</button>
      </div>
      <hr style={{width: '75%'}}/>
      <div className="padded"><button className="padded" onClick={paste}>Paste Addresses</button><button className="padded" onClick={copy}>Copy Data</button><button className="padded" onClick={exportCSV}>Export CSV</button></div>
      <ReactGrid enableRangeSelection stickyRightColumns={1} onCellsChanged={handleChanges} rows={rows} columns={columns} />
      <div className="padded"><button className="padded" onClick={add50Rows}>Add 50 rows</button><button className="padded" onClick={copy}>Copy Data</button><button className="padded" onClick={exportCSV}>Export CSV</button></div>
      <div className="padded">Total Balance: {printNumber(sum)} <button className="padded" onClick={copySum}>Copy</button></div>
    </div>
  </div>
}

function printNumber(num) {
  if (typeof num === "number") {
    return num.toLocaleString(undefined, { maximumFractionDigits: 2 });
  }
  return num;
}

export default App
